import { IEvent } from '@renovars/common';

export enum PltEventType {
  SOPRALLUOGO = 1,
  INSTALLAZIONE = 2,
}
export enum PltEventLabelType {
  'sopralluogo' = 1,
  'installazione' = 2,
}
export interface IPltEvent extends IEvent {}
