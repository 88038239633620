import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'plt-flow-tab',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">Lead</h1>
    </ng-template>
    <ng-template header-menu>
      <fullstack-tabs>
        <tab [label]="'Dettaglio'" [url]="'./upsert'"></tab>
        <tab *ngIf="id | async" [label]="'Storico'" [url]="'./history'"></tab>
      </fullstack-tabs>
    </ng-template>
    <div class="m-2">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [],
})
export class LeadTabComponent implements OnInit {
  id: Observable<boolean>;
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.id = this.route.queryParamMap.pipe(map((params) => params.has('id')));
  }
}
