import { IJwtPayload } from '@renovars/common';

export enum PltRoles {
  is_admin = 'is_admin',
  is_cc_operator = 'is_cc_operator',
  is_technical_coordinator = 'is_technical_coordinator',
  is_installer = 'is_installer',
  is_bo_operator = 'is_bo_operator',
}
export enum PltRolesLabel {
  is_admin = 'Admin',
  is_cc_operator = 'Operatore CC',
  is_technical_coordinator = 'Coordinatore Tecnico',
  is_installer = 'Tecnico Installatore',
  is_bo_operator = 'Operatore BO',
}

export const PltRolesLevels = [PltRoles.is_cc_operator];

export function getUpline(roleStart: PltRoles) {
  let roleLevelStartIndex = PltRolesLevels.findIndex((r) => r == roleStart);
  return PltRolesLevels.slice(roleLevelStartIndex);
}

export function getPltUserRole(u: IJwtPayload) {
  if (u.roles.includes(PltRoles.is_admin)) return PltRoles.is_admin;
  if (u.roles.includes(PltRoles.is_cc_operator)) return PltRoles.is_cc_operator;
  if (u.roles.includes(PltRoles.is_technical_coordinator)) return PltRoles.is_technical_coordinator;
  if (u.roles.includes(PltRoles.is_installer)) return PltRoles.is_installer;
  if (u.roles.includes(PltRoles.is_bo_operator)) return PltRoles.is_bo_operator;
}
