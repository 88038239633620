import { Component, forwardRef, OnInit } from '@angular/core';
import {
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ISite } from '@renovars/common';
import { Lead, UpdateLeadProperty } from '@renovars/common/plenitude';
import { SiteService } from '@renovars/core-ng';
import { FormElement } from '@renovars/ui-ng';
import { Observable } from 'rxjs';

@Component({
  selector: 'lead-form',
  template: `
    <form [formGroup]="leadForm" novalidate>
      <div class="row mt-4">
        <div class="col-12 my-4">
          <header class="page-header">
            <h2 class="tx-title-2">Dati aggiuntivi</h2>
          </header>
        </div>
        <div class="col-sm-4 col-md-4">
          <fullstack-input-select-box
            formControlName="addressOp"
            [options]="{ label: 'Città *' }"
            [isAddress]="true"
          >
            <sb-option [value]="s.address" *ngFor="let s of sites$ | async">{{ s.name }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-4 col-md-4">
          <fullstack-input-select-box
            formControlName="forProduct"
            [options]="{ label: 'Interessato a *' }"
            [error]="leadForm.get('forProduct').errors"
          >
            <sb-option [value]="'Caldaia'">Caldaia</sb-option>
            <sb-option [value]="'Climitizzatore'">Climitizzatore</sb-option>
            <sb-option [value]="'Caldaia e Climitizzatore'">Caldaia e Climitizzatore</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-4 col-md-4">
          <fullstack-input-select-box
            formControlName="callPreference"
            [options]="{ label: 'Diponibilità *' }"
            [error]="leadForm.get('callPreference').errors"
          >
            <sb-option [value]="'08:30/11:30'">08:30/11:30</sb-option>
            <sb-option [value]="'11:30/14:30'">11:30/14:30</sb-option>
            <sb-option [value]="'14:30/17:30'">14:30/17:30</sb-option>
            <sb-option [value]="'17:30/20:30'">17:30/20:30</sb-option>
          </fullstack-input-select-box>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-4 col-md-4">
          <fullstack-input-select-box
            formControlName="isOwner"
            [options]="{ label: 'Propetario o Affittuario' }"
          >
            <sb-option [value]="true">Propetario</sb-option>
            <sb-option [value]="false">Affittuario</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-4 col-md-4">
          <fullstack-input-select-box
            formControlName="centralizedWarming"
            [options]="{ label: 'Riscaldamento Centrallizzato o Autonomo' }"
          >
            <sb-option [value]="true">Centrallizzato</sb-option>
            <sb-option [value]="false">Autonomo</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-4 col-md-4">
          <fullstack-input-box formControlName="mq" [options]="{ label: 'Mq Immobile' }">
          </fullstack-input-box>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-3 col-md-3">
          <fullstack-input-box formControlName="bathNumber" [options]="{ label: 'Numero Bagni' }">
          </fullstack-input-box>
        </div>
        <div class="col-sm-3 col-md-3">
          <fullstack-input-box
            formControlName="boilerLocation"
            [options]="{ label: 'Posizione della Caldaia' }"
          >
          </fullstack-input-box>
        </div>
        <div class="col-sm-3 col-md-3">
          <fullstack-input-box formControlName="fireplaceType" [options]="{ label: 'Tipo canna fumaria' }">
          </fullstack-input-box>
        </div>
        <div class="col-sm-3 col-md-3">
          <fullstack-input-box formControlName="paymentMethod" [options]="{ label: 'Metodo di Pagamento' }">
          </fullstack-input-box>
        </div>
        <div class="col-sm mt-2">
          <fullstack-input-textarea
            [options]="{ label: 'Note', rows: 5 }"
            formControlName="note"
          ></fullstack-input-textarea>
        </div>
      </div>
      <header class="page-header mt-2">
        <h2 class="tx-title-2">Privacy</h2>
      </header>
      <div class="row" formGroupName="privacy">
        <div class="row mt-2">
          <h2 class="tx-title-4">Marketing</h2>
          <div class="col-6">
            <p-radioButton [value]="true" formControlName="marketing" label="Si"></p-radioButton>
          </div>
          <div class="col-6">
            <p-radioButton [value]="false" formControlName="marketing" label="No"></p-radioButton>
          </div>
        </div>
        <div class="row mt-2">
          <h2 class="tx-title-4">Comunicazioni promozionali</h2>
          <div class="col-6">
            <p-radioButton [value]="true" formControlName="commercial" label="Si"></p-radioButton>
          </div>
          <div class="col-6">
            <p-radioButton [value]="false" formControlName="commercial" label="No"></p-radioButton>
          </div>
        </div>
        <div class="row mt-2">
          <h2 class="tx-title-4">Profilazione</h2>
          <div class="col-6">
            <p-radioButton [value]="true" formControlName="profile" label="Si"></p-radioButton>
          </div>
          <div class="col-6">
            <p-radioButton [value]="false" formControlName="profile" label="No"></p-radioButton>
          </div>
        </div>
        <div class="row mt-2">
          <h2 class="tx-title-4">Soggetti terzi</h2>
          <div class="col-6">
            <p-radioButton [value]="true" formControlName="otherSub" label="Si"></p-radioButton>
          </div>
          <div class="col-6">
            <p-radioButton [value]="false" formControlName="otherSub" label="No"></p-radioButton>
          </div>
        </div>
      </div>
    </form>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LeadFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: LeadFormComponent,
      multi: true,
    },
  ],
})
export class LeadFormComponent extends FormElement implements OnInit {
  leadForm: UntypedFormGroup;
  sites$: Observable<ISite[]>;
  propagateChange = (_: Lead) => {
    //
  };
  constructor(private fb: FormBuilder, private siteService: SiteService) {
    super();
  }
  ngOnInit(): void {
    this.leadForm = this.fb.group({
      addressOp: new UntypedFormControl(null, []),
      callPreference: new UntypedFormControl(null, []),
      forProduct: new UntypedFormControl(null, []),
      isOwner: new UntypedFormControl(null, []),
      centralizedWarming: new UntypedFormControl(null, []),
      mq: new UntypedFormControl(null, []),
      bathNumber: new UntypedFormControl(null, []),
      boilerLocation: new UntypedFormControl(null, []),
      fireplaceType: new UntypedFormControl(null, []),
      paymentMethod: new UntypedFormControl(null, []),
      note: new UntypedFormControl(null, []),
      privacy: this.fb.group({
        marketing: new UntypedFormControl(false, []),
        commercial: new UntypedFormControl(false, []),
        profile: new UntypedFormControl(false, []),
        otherSub: new UntypedFormControl(false, []),
      }),
    });
    this.sites$ = this.siteService.findAll();
    this.leadForm.valueChanges.subscribe((value) => this.propagateChange(value));
  }
  writeValue(obj: UpdateLeadProperty): void {
    this.leadForm.patchValue(obj);
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
}
