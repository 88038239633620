import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICommandHistory, ITimelineUiItem } from '@renovars/common';
import {
  CreatePltOpportunityFlowDTO,
  ICreateOpportinityFromContactDTO,
  IUpdateLead,
  Lead,
  Opportinity,
  PltFlow,
  PltOpportunityStatusCode,
} from '@renovars/common/plenitude';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/plenitude-ng/src/environments/environment';
import { MessageService } from 'primeng/api';
import { catchError, delay, map, Observable, of, switchMap, tap } from 'rxjs';
import { CsvUtilsService } from '../../services/csv-utils.service';

@Injectable()
export class OpportunityApiService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private csvUtils: CsvUtilsService
  ) {}
  createFlow(data: CreatePltOpportunityFlowDTO) {
    return this.http.post(`${environment.apiGatewayURL}/plenitude/opportunities`, data).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Opportunità creato con successo',
        });
      }),
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: err?.message,
        });
        return of(err);
      }),
      delay(1000)
    );
  }
  createFlowWithNewContact(data: ICreateOpportinityFromContactDTO) {
    return this.http.post(`${environment.apiGatewayURL}/plenitude/opportunities/newcontact`, data).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Opportunità creato con successo',
        });
      }),
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: err?.message,
        });
        return of(null);
      }),
      delay(1000)
    );
  }
  /**
   * esempio
      {
        offset: 0,
        limit: 10,
        filter: {
          'cache.data.contact.lastName': {
            $regex: 'ze',
          },
        },
      }
   */
  createBrowsingFilter(value: any) {
    return Object.keys(value).reduce((a, key) => {
      if (!value[key]) {
        return a;
      } else {
        if (key === 'cache.data.contact.phone' && value[key])
          return { ...a, 'cache.data.contact.phones.phoneNumber': { $regex: value[key] } };
        if (typeof value[key] === 'string') return { ...a, [key]: { $regex: value[key] } };
        if (typeof value[key] === 'number') return { ...a, [key]: { $eq: value[key] } };
        return { ...a, [key]: value[key] };
      }
    }, {});
  }
  browse(
    filter:
      | {
          offset: number;
          limit: number;
          filter: any;
          sort: [{ by: string; oreder: number }];
        }
      | any
  ): Observable<{ items: PltFlow[]; total: number }> {
    console.log('parse', this.createBrowsingFilter(filter.filter));
    return this.http.post<{ items: PltFlow[]; total: number }>(
      `${environment.apiGatewayURL}/plenitude/opportunities/browse`,
      {
        offset: filter.offset,
        limit: filter.limit,
        sort: filter.sort,
        filter: this.createBrowsingFilter(filter.filter),
      }
    );
  }
  browseForCsv(
    filter:
      | {
          offset: number;
          limit: number;
          sort: [{ by: string; order: number }];
          filter: any;
        }
      | any
  ) {
    return this.http
      .post<{ items: PltFlow[]; total: number }>(
        `${environment.apiGatewayURL}/plenitude/opportunities/browse`,
        {
          offset: 0,
          limit: 1000,
          sort: filter.sort,
          filter: this.createBrowsingFilter(filter.filter),
        }
      )
      .pipe(
        map((result) => result.items),
        switchMap((values) => this.csvUtils.mapCsv(of(values), 'lead'))
      );
  }
  getById(id: string) {
    return this.http.get<Opportinity>(`${environment.apiGatewayURL}/plenitude/opportunities/${id}`);
  }
  moveState(id: string, command: Partial<ICommandHistory<typeof PltOpportunityStatusCode>>) {
    return this.http
      .put<{ items: PltFlow[]; total: number }>(
        `${environment.apiGatewayURL}/plenitude/opportunities/status/${id}`,
        command
      )
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Opportunità aggiornato con successo',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }
  addAttachment(id: string, fileId: string) {
    return this.http
      .post(`${environment.apiGatewayURL}/plenitude/opportunity/attachments/${id}`, { fileId: fileId })
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Opportunità aggiornato con successo',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        })
      );
  }
  updateLead(leadId: string, data: IUpdateLead) {
    return this.http.put<Lead>(`${environment.apiGatewayURL}/plenitude/leads/${leadId}`, data).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Opportunità aggiornata con successo',
        });
      }),
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: err?.message,
        });
        return of(err);
      })
    );
  }
  getTimelineById(id: string) {
    return this.http.get<ITimelineUiItem[]>(
      `${environment.apiGatewayURL}/plenitude/opportunity/timeline/${id}`
    );
  }
}
