import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HistoryComponent } from '../../components/history.component';
import { OpportunityDocumentsComponent } from './component/opportunity-documents.component';
import { OpportunityListComponent } from './component/opportunity-list.component';
import { OpportunityTabComponent } from './component/opportunity-tab.component';
import { OpportunityUpsertFormComponent } from './component/opportunity-upsert-form.component';

const routes: Routes = [
  {
    path: '',
    component: OpportunityListComponent,
  },
  {
    path: 'details',
    component: OpportunityTabComponent,
    children: [
      {
        path: 'upsert',
        component: OpportunityUpsertFormComponent,
      },
      {
        path: 'documents',
        component: OpportunityDocumentsComponent,
      },
      {
        path: 'history',
        component: HistoryComponent,
        data: {
          type: 'opportunity',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OpportunityRoutingModule {}
