import { PltLeadStatusCode, PltOpportunityStatusCode } from '../enums';

const koContatto = [
  PltLeadStatusCode.KO_SOLO_INFO,
  PltLeadStatusCode.KO_CITTA,
  PltLeadStatusCode.KO_CREDITO,
  PltLeadStatusCode.KO_SOLO_UTENZE,
];
const okSopralluogo = [PltLeadStatusCode.OK_SOPRALLUOGO_PRODOTTO, PltLeadStatusCode.OK_SOPRALLUOGO_UTENZE];
const koTecnici = [
  PltLeadStatusCode.KO_TECNICO,
  PltLeadStatusCode.KO_PREZZO,
  PltLeadStatusCode.KO_RIPENSAMENTO,
];
const koLavorazione = [
  PltOpportunityStatusCode.KO_ATTESA_DOCUMENTI,
  PltOpportunityStatusCode.KO_MANCANZA_DOCUMENTI,
  PltOpportunityStatusCode.KO_ATTESA_PAGAMENTO,
  PltOpportunityStatusCode.KO_FINANZIAMENTO,
  PltOpportunityStatusCode.KO_REPERIBILITA_OPPORTUNITA,
];
export const ccOperatorAction = {
  DA_CONTATTARE: [...okSopralluogo, PltLeadStatusCode.DA_RICHIAMARE, ...koContatto],
  DA_RICHIAMARE: [...okSopralluogo, ...koContatto],
  KO_SOLO_INFO: [PltLeadStatusCode.DA_RICHIAMARE],
  KO_CITTA: [PltLeadStatusCode.DA_RICHIAMARE],
  KO_CREDITO: [PltLeadStatusCode.DA_RICHIAMARE],
  KO_SOLO_UTENZE: [PltLeadStatusCode.DA_RICHIAMARE],
  OK_SOPRALLUOGO_PRODOTTO: [PltLeadStatusCode.SOPRALLUOGO_FISSATO],
  OK_SOPRALLUOGO_UTENZE: [PltLeadStatusCode.SOPRALLUOGO_FISSATO],
};
export const leadInstallerAction = {
  ASSEGNAZIONE_TECNICO_INSTALLATORE: [...koTecnici, PltLeadStatusCode.SOPRALLUOGO_EFFETTUATO],
  KO_TECNICO: [PltLeadStatusCode.DA_RICHIAMARE],
  KO_PREZZO: [PltLeadStatusCode.DA_RICHIAMARE],
  KO_RIPENSAMENTO: [PltLeadStatusCode.DA_RICHIAMARE],
};
export const opportunityInstallerAction = {
  DA_INSTALLARE: [
    PltOpportunityStatusCode.INSTALLAZIONE_DOCUMENTI_OK_OK,
    PltOpportunityStatusCode.INSTALLAZIONE_DOCUMENTI_OK_PENDING,
  ],
  INSTALLAZIONE_DOCUMENTI_OK_PENDING: [PltOpportunityStatusCode.INSTALLAZIONE_DOCUMENTI_OK_OK],
  INSTALLAZIONE_DOCUMENTI_OK_OK: [PltOpportunityStatusCode.PRATICA_CHIUSA],
};
export const boOperatorAction = {
  SOPRALLUOGO_EFFETTUATO: [PltOpportunityStatusCode.IN_LAVORAZIONE],
  IN_LAVORAZIONE: [...koLavorazione, PltOpportunityStatusCode.DA_INSTALLARE],
  INSTALLAZIONE_DOCUMENTI_OK_OK: [PltOpportunityStatusCode.PRATICA_CHIUSA],
  KO_ATTESA_DOCUMENTI: [PltOpportunityStatusCode.IN_LAVORAZIONE],
  KO_MANCANZA_DOCUMENTI: [PltOpportunityStatusCode.IN_LAVORAZIONE],
  KO_ATTESA_PAGAMENTO: [PltOpportunityStatusCode.IN_LAVORAZIONE],
  KO_FINANZIAMENTO: [PltOpportunityStatusCode.IN_LAVORAZIONE],
  KO_REPERIBILITA_OPPORTUNITA: [PltOpportunityStatusCode.IN_LAVORAZIONE],
};
export const technicalCoordinatorAction = {
  SOPRALLUOGO_FISSATO: [PltLeadStatusCode.ASSEGNAZIONE_TECNICO_INSTALLATORE],
  ...leadInstallerAction,
};
export const opportunityTechnicalCoordinatorAction = {
  ...opportunityInstallerAction,
  ...boOperatorAction,
};
export const leadAdminAction = {
  ...ccOperatorAction,
  ...technicalCoordinatorAction,
  ...leadInstallerAction,
};
export const opportunityAdminAction = {
  ...opportunityInstallerAction,
  ...boOperatorAction,
};
