import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IEvent } from '../../../../../libs/shared/common/src';
import { environment } from '../../environments/environment';

@Injectable()
export class OpportunityEventService {
  environment = environment;
  constructor(private http: HttpClient) {}
  createOpportunityEvent(opportunityId: string, event: IEvent) {
    return this.http.post(
      `${environment.apiGatewayURL}/plenitude/events/opportunities/${opportunityId}`,
      event
    );
  }
}
