import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeadListComponent } from './component/lead-list.component';
import { LeadTabComponent } from './component/lead-tab.component';
import { LeadUpsertFormComponent } from './component/lead-upsert-form.component';
import { HistoryComponent } from '../../components/history.component';
const routes: Routes = [
  {
    path: '',
    component: LeadListComponent,
  },
  {
    path: 'details',
    component: LeadTabComponent,
    children: [
      {
        path: 'upsert',
        component: LeadUpsertFormComponent,
      },
      {
        path: 'history',
        component: HistoryComponent,
        data: {
          type: 'lead',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LeadRoutingModule {}
