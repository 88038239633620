import {
  IAddress,
  IAppointmentSearchDTO,
  IContact,
  IContactCompany,
  IContactIndividual,
  IStatus,
  ITrackingData,
  IUser,
} from '@renovars/common';

export interface ICrmFRRegistration {
  username?: string;
  workingEmail?: string;
  parent?: string;
  type?: string;
  shop?: string;
  radius?: number;
  note?: string;
  typology?: 'Architetto' | 'Geometra' | 'Ingegnere';
  frAddress?: IAddress;
  contract?: string;
  senior?: string;
  assistantEmploymentDate?: Date;
}
export interface ICrmFRData {
  crmFrId?: string;
  registration?: ICrmFRRegistration;
}

export interface ICandidate {
  readonly object?: string;
  id?: string;
  _id?: string;
  type?: CandidateType | string;
  contact: IContact | string; // contact:id (external db)
  addressOp: IAddress;
  profile?: ICandidateProfile | ICandidateCompanyProfile;
  note?: string;
  attachments?: string[]; // File pdf CV: string:hash.pdf[]
  durc?: string[]; // File pdf durc: string:hash.pdf[]
  visura?: string[]; // File pdf visura: string:hash.pdf[]
  sector?: string;
  image?: string; // string:hash.jpg
  assigned?: IUser | string; // store manager: user:id:uuid (external db)
  assignedHR?: IUser | string; //hr assigned by admin
  status?: IStatus;
  createdBy?: IUser | string;
  createdAt?: string;
  updatedAt?: string;
  assignedAt?: any;
  assignedHRAt?: any;
  duplicated?: boolean;
  crmFrSubappaltatoreId?: string;
  lastAppointment?: string;
  candidateSource?: string;
  candidateCampaign?: string;
  trackingUrl?: string;
  tracking?: ITrackingData;
  crmFrData?: ICrmFRData;
  cache?: {
    contact: IContactIndividual | IContactCompany;
  };
}

export interface ICandidateProfile {
  married?: boolean;
  childs?: number;
  cohabitant?: boolean;
  workQualificationDate?: string; // Date
  vatNumber: string;
  vatNumberOpenInterest?: boolean;
  ownCar?: boolean;
  // ownMoto?: boolean;
  workSupervisionExperience?: string;
  workStatus?: string;
  workStatusDescription?: string;
  exclusiveCollaboration?: boolean;
  commercialExperience?: boolean;
  commercialExperienceDescription?: string;
  register?: string;
  registerNum?: string;
  registerDate?: Date;
  profileNote?: string;
  lastWorkingExperience?: string;
}

export interface ICandidateCompanyProfile {
  validDurc?: boolean;
  expireDurcDate?: Date;
  externalSecurityConsultant?: boolean;
  installationCertification?: boolean;
  dvr?: boolean;
  noiseAssessment?: boolean;
  rspp?: boolean;
  competentDoctor?: boolean;
  trainingMedicalExams?: boolean;
  thirdPartyInsurance?: boolean;
  workerHighRisk?: boolean;
  firstAid?: boolean;
  employeesNumber?: number;
  profileNote?: string;
  sector?: string;
}

export enum CandidateType {
  COMPANY = 'imprese',
  ARCHITECT = 'architetti',
  WORKER = 'operai',
  OPERATOR = 'operatori_telefonici',
}

export const CandidateTypeSector = {
  [CandidateType.COMPANY]: ['Edile', 'Idraulico', 'Elettrico'],
  [CandidateType.WORKER]: ['Edile', 'Impiantista', 'Posatore serramenti'],
  [CandidateType.ARCHITECT]: [],
};

export namespace CandidateType {
  export function toLabel(candidateType: CandidateType): string {
    switch (candidateType) {
      case CandidateType.COMPANY:
        return 'Imprese';
      case CandidateType.ARCHITECT:
        return 'Architetti';
      case CandidateType.WORKER:
        return 'Operai';
      case CandidateType.OPERATOR:
        return 'Operatori Telefonici';
      default:
        return '';
    }
  }
}

export interface RecruitAppointment extends IAppointmentSearchDTO {
  candidate?: ICandidate;
}
export interface IFRSearchResult {
  value: string;
  code: string;
  email: string;
  ancestors: string[];
  childrens: any[];
  assistants: string[];
}
