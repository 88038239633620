import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssignedPipe, GetEmailPipe, GetPhonePipe, GetRolesPipe, NameByIdPipe } from './utils.pipe';

const DECLARATIONS = [GetRolesPipe, NameByIdPipe, AssignedPipe, GetEmailPipe, GetPhonePipe];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [CommonModule],
  providers: [],
  exports: [...DECLARATIONS],
})
export class PipesModule {}
