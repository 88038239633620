import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared.module';
import { ContactModule, FlowClientModule } from '@renovars/core-ng';
import {
  boOperatorAction,
  opportunityInstallerAction,
  PltRoles,
  PltStatusLabels,
  opportunityAdminAction,
  PltOpportunityStatusCode,
  opportunityTechnicalCoordinatorAction,
} from '@renovars/common/plenitude';
import { SharedComponentsModule } from '../../components/components.module';
import { OpportunityApiService } from './opportunity-api.service';
import { OpportunityDocumentsComponent } from './component/opportunity-documents.component';
import { OpportunityTabComponent } from './component/opportunity-tab.component';
import { OpportunityFromNewContactFormComponent } from './component/opportunity-from-new-contact-form.component';
import { OpportunityListComponent } from './component/opportunity-list.component';
import { OpportunityCreateEventModalComponent } from './modals/opportunity-create-event-modal.component';
import { OpportunityMoveStateComponent } from './modals/opportunity-move-state.component';
import { OpportunityRoutingModule } from './opportunity-routing.module';
import { PipesModule } from '../../pipes/pipes.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '../../forms/forms.module';
import { OpportunityUpsertFormComponent } from './component/opportunity-upsert-form.component';
@NgModule({
  declarations: [
    OpportunityListComponent,
    OpportunityFromNewContactFormComponent,
    OpportunityUpsertFormComponent,
    OpportunityCreateEventModalComponent,
    OpportunityMoveStateComponent,
    OpportunityTabComponent,
    OpportunityDocumentsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    OpportunityRoutingModule,
    ContactModule,
    FlowClientModule.register<typeof PltRoles, typeof PltOpportunityStatusCode, typeof PltStatusLabels>({
      labels: PltStatusLabels,
      sd: {
        is_admin: opportunityAdminAction,
        is_cc_operator: {},
        is_technical_coordinator: opportunityTechnicalCoordinatorAction,
        is_installer: opportunityInstallerAction,
        is_bo_operator: boOperatorAction,
      },
    }),
    SharedComponentsModule,
    PipesModule,
    RadioButtonModule,
    FormsModule,
  ],
  providers: [OpportunityApiService],
})
export class OpportunityModule {}
