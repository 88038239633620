import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PltEventType } from '@renovars/common/plenitude';
import { IEvent } from '../../../../../../libs/shared/common/src';

@Component({
  selector: 'plt-event-modal',
  templateUrl: `plt-event-modal.html`,
  styles: [``],
})
export class PltEventModal implements OnInit {
  event: IEvent & { cache: any } & { flow: string };

  constructor(
    public dialogRef: MatDialogRef<PltEventModal>,
    @Inject(MAT_DIALOG_DATA) public data: { event: IEvent & { cache: any } & { flow: string } },
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.event = data.event;
  }

  ngOnInit(): void {}

  goToDetail() {
    if (this.event.type === PltEventType.SOPRALLUOGO) {
      this.router.navigateByUrl(`/flows/details/upsert?id=${this.event.flow}`);
    } else {
      this.router.navigateByUrl(`/flows/details/upsert?id=${this.event.flow}`);
    }
  }

  close() {
    this.dialogRef.close();
  }
  // move() {
  //   this.dialogRef.close({ move: true, event: this.event });
  // }
}
