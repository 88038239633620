import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IUser } from '@renovars/common';
import {
  PltEventType,
  PltFlow,
  PltRoles,
  PltLeadStatusCode,
  PltStatusLabels,
  PltOpportunityStatusCode,
  getPltUserRole,
  Opportinity,
} from '@renovars/common/plenitude';
import { getEnumKeyByEnumValue } from '@renovars/common/recruit';
import { AuthServices, FlowMenuService } from '@renovars/core-ng';
import { MenuItem } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { PltUsersCacheService } from '../../../services/plt-users-cache.service';
import { OpportunityCreateEventModalComponent } from '../modals/opportunity-create-event-modal.component';
import { OpportunityMoveStateComponent } from '../modals/opportunity-move-state.component';
import { OpportunityApiService } from '../opportunity-api.service';

@Component({
  selector: 'opportunity-list',
  templateUrl: './opportunity-list.component.html',
  styles: [],
})
export class OpportunityListComponent {
  cmItems: MenuItem[];
  items: PltFlow[];
  total: number;
  opportunity: Opportinity;
  labels = PltStatusLabels;
  codes = Object.values(PltLeadStatusCode);
  selectableUsers$: Observable<IUser[]>;
  filter = {
    createdBy: '',
    assigned: '',
    'cache.contact.lastName': '',
    'cache.contact.firstName': '',
    'cache.contact.phone': '',
    'cache.lead.addressOp.locality': '',
    'status.code': '',
    'privacy.marketing': null,
    'privacy.commercial': null,
    'privacy.profile': null,
    'privacy.otherSub': null,
  };
  offset = 0;
  limit = 10;
  constructor(
    private flowMenuService: FlowMenuService<
      typeof PltRoles,
      typeof PltOpportunityStatusCode,
      PltStatusLabels
    >,
    private pltFlowService: OpportunityApiService,
    private cacheUserService: PltUsersCacheService,
    private authService: AuthServices,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.selectableUsers$ = this.cacheUserService.getUsers();
  }
  load() {
    this.pltFlowService
      .browse({
        offset: this.offset,
        limit: this.limit,
        filter: this.filter,
        sort: [{ by: '_id', order: -1 }],
      })
      .subscribe((res) => {
        this.items = res.items;
        this.total = res.total;
      });
  }
  onLazyLoad(event) {
    this.offset = event.first ?? 0;
    this.limit = event.rows ?? 10;
    this.load();
  }
  onSelect(selectedOpportunity: Opportinity) {
    if (selectedOpportunity) {
      this.authService
        .user()
        .pipe(
          map((u) =>
            this.flowMenuService
              .createMenuVoice(selectedOpportunity.status.code, getPltUserRole(u))
              .map((voice) => {
                const item: MenuItem = {
                  label: PltStatusLabels[voice],
                  icon: voice.includes('KO')
                    ? 'pi pi-ban'
                    : voice.includes('OK')
                    ? 'pi pi-check-circle'
                    : 'pi pi-calendar',
                  command: (event) => {
                    if (!event.item.label) {
                      return;
                    }
                    const status = getEnumKeyByEnumValue(PltStatusLabels, event.item.label);
                    if (status.includes(PltOpportunityStatusCode.DA_INSTALLARE)) {
                      this.dialog
                        .open(OpportunityCreateEventModalComponent, {
                          data: {
                            opportunity: selectedOpportunity,
                            type: PltEventType.INSTALLAZIONE,
                          },
                        })
                        .afterClosed()
                        .subscribe(() => this.load());
                    } else {
                      this.dialog
                        .open(OpportunityMoveStateComponent, {
                          data: {
                            opportunity: selectedOpportunity,
                            code: status,
                          },
                        })
                        .afterClosed()
                        .subscribe(() => this.load());
                    }
                  },
                };
                return item;
              })
          )
        )
        .subscribe((items) => {
          this.cmItems = items;
          this.cmItems.push({
            label: 'Dettaglio',
            icon: 'pi pi-check-circle',
            command: () => {
              this.router.navigateByUrl(`opportunity/details/upsert?id=${selectedOpportunity._id}`);
            },
          });
          this.cmItems.push({
            label: 'Vai al lead',
            icon: 'pi pi-check-circle',
            command: () => {
              this.router.navigateByUrl(`flows/details/upsert?id=${selectedOpportunity.lead}`);
            },
          });
        });
    }
  }
  downloadCsv() {
    return this.pltFlowService
      .browseForCsv({
        offset: this.offset,
        limit: this.limit,
        filter: this.filter,
        sort: [
          {
            by: '_id',
            order: -1,
          },
        ],
      })
      .subscribe();
  }
}
