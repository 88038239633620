import { Component, Inject } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Lead, PltLeadStatusCode } from '@renovars/common/plenitude';
import { OpportunityApiService } from '../../plt-opportunity/opportunity-api.service';

@Component({
  selector: 'create-opportunity',
  template: `
    <div mat-dialog-content>
      <form [formGroup]="form" (submit)="save()">
        <div class="row">
          <div class="col-12 mt-1">
            <fullstack-radio-button formControlName="choice">
              <item [label]="'Genera dal lead attuale'" [value]="'lead'"></item>
              <item [label]="'Crea nuovo lead'" [value]="'new'"></item>
            </fullstack-radio-button>
          </div>
        </div>

        <div class="text-end">
          <button type="button" class="btn btn-default rounded m-1" (click)="gotoList()">Annulla</button>
          <button [disabled]="!form.valid" type="submit" class="btn btn-warning rounded m-1">
            Crea Opportunità
          </button>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class CreateOpportunityComponent {
  form: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { lead: Lead; code: PltLeadStatusCode },
    private fb: FormBuilder,
    private pltOpportunityFlowService: OpportunityApiService,
    private router: Router,
    public dialogRef: MatDialogRef<CreateOpportunityComponent>
  ) {
    this.form = this.fb.group({
      choice: new UntypedFormControl('', []),
    });
  }
  gotoList() {
    this.dialogRef.close();
  }
  save() {
    if (this.form.get('choice').value === 'lead') {
      this.pltOpportunityFlowService
        .createFlow({
          contact: this.data.lead.contact,
          lead: this.data.lead._id,
        })
        .subscribe(() => {
          this.dialogRef.close();
          this.gotoList();
        });
    } else {
      this.router
        .navigateByUrl(`opportunity/details/upsert?id=${this.data.lead._id}`)
        .then(() => this.dialogRef.close());
    }
  }
}
