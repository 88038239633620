export enum PltLeadStatusCode {
  DA_CONTATTARE = 'DA_CONTATTARE',
  DA_RICHIAMARE = 'DA_RICHIAMARE',
  KO_SOLO_INFO = 'KO_SOLO_INFO',
  KO_CITTA = 'KO_CITTA',
  KO_CREDITO = 'KO_CREDITO',
  KO_SOLO_UTENZE = 'KO_SOLO_UTENZE',
  OK_SOPRALLUOGO_PRODOTTO = 'OK_SOPRALLUOGO_PRODOTTO',
  OK_SOPRALLUOGO_UTENZE = 'OK_SOPRALLUOGO_UTENZE',
  SOPRALLUOGO_FISSATO = 'SOPRALLUOGO_FISSATO',
  ASSEGNAZIONE_TECNICO_INSTALLATORE = 'ASSEGNAZIONE_TECNICO_INSTALLATORE',
  KO_TECNICO = 'KO_TECNICO',
  KO_PREZZO = 'KO_PREZZO',
  KO_RIPENSAMENTO = 'KO_RIPENSAMENTO',
  SOPRALLUOGO_EFFETTUATO = 'SOPRALLUOGO_EFFETTUATO',
}

export enum PltOpportunityStatusCode {
  SOPRALLUOGO_EFFETTUATO = 'SOPRALLUOGO_EFFETTUATO',
  IN_LAVORAZIONE = 'IN_LAVORAZIONE',
  KO_ATTESA_DOCUMENTI = 'KO_ATTESA_DOCUMENTI',
  KO_MANCANZA_DOCUMENTI = 'KO_MANCANZA_DOCUMENTI',
  KO_ATTESA_PAGAMENTO = 'KO_ATTESA_PAGAMENTO',
  KO_FINANZIAMENTO = 'KO_FINANZIAMENTO',
  KO_REPERIBILITA_OPPORTUNITA = 'KO_REPERIBILITA_OPPORTUNITA',
  DA_INSTALLARE = 'DA_INSTALLARE',
  INSTALLAZIONE_DOCUMENTI_OK_OK = 'INSTALLAZIONE_DOCUMENTI_OK_OK',
  INSTALLAZIONE_DOCUMENTI_OK_PENDING = 'INSTALLAZIONE_DOCUMENTI_OK_PENDING',
  PRATICA_CHIUSA = 'PRATICA_CHIUSA',
}

export enum PltStatusLabels {
  DA_CONTATTARE = 'da contattare',
  DA_RICHIAMARE = 'da richiamare',
  KO_SOLO_INFO = 'ko solo info',
  KO_CITTA = 'ko città',
  KO_CREDITO = 'ko credito',
  KO_SOLO_UTENZE = 'ko solo utenze',
  OK_SOPRALLUOGO_PRODOTTO = 'ok sopralluogo prodotto',
  OK_SOPRALLUOGO_UTENZE = 'ok sopralluogo utenze',
  SOPRALLUOGO_FISSATO = 'sopralluogo fissato',
  ASSEGNAZIONE_TECNICO_INSTALLATORE = 'assegnazione tecnico installatore',
  KO_TECNICO = 'ko tecnico',
  KO_PREZZO = 'ko prezzo',
  KO_RIPENSAMENTO = 'ko ripensamento',
  SOPRALLUOGO_EFFETTUATO = 'sopralluogo effettuato',
  IN_LAVORAZIONE = 'in lavorazione',
  KO_ATTESA_DOCUMENTI = 'ko attesa documenti',
  KO_MANCANZA_DOCUMENTI = 'ko mancanza documenti',
  KO_ATTESA_PAGAMENTO = 'ko attesa pagamento',
  KO_FINANZIAMENTO = 'ko finanziamento',
  KO_REPERIBILITA_OPPORTUNITA = 'ko reperibilità opportunità',
  DA_INSTALLARE = 'da installare',
  INSTALLAZIONE_DOCUMENTI_OK_OK = 'installazione ok documenti ok ',
  INSTALLAZIONE_DOCUMENTI_OK_PENDING = 'installazione ok attesa documenti',
  PRATICA_CHIUSA = 'pratica chiusa',
}
