export enum StatusCodesAll {
  ADMIN_ASSEGNATO = 'ADMIN_ASSEGNATO',
  ADMIN_SENIOR_ATTIVATO = 'ADMIN_SENIOR_ATTIVATO',
  //HS
  HS_FOLLOWUP = 'HS_FOLLOWUP',
  HS_DA_RICHIAMARE = 'HS_DA_RICHIAMARE',
  HS_SOLLECITO = 'HS_SOLLECITO',
  HS_KO_NON_INTERESSATO_RIMBORSO_SPESE = 'HS_KO_NON_INTERESSATO_RIMBORSO_SPESE',
  HS_KO_NON_INTERESSATO_LAVORO = 'HS_KO_NON_INTERESSATO_LAVORO',
  HS_KO_NON_INTERESSATO_TRASFERIMENTO = 'HS_KO_NON_INTERESSATO_TRASFERIMENTO',
  HS_KO_NON_INTERESSATO_ALTRO = 'HS_KO_NON_INTERESSATO_ALTRO',
  HS_KO_NON_INTERESSATO_ABILITAZIONE = 'HS_KO_NON_INTERESSATO_ABILITAZIONE',
  HS_KO_NON_A_TARGET = 'HS_KO_NON_A_TARGET',
  HS_OK_PRIMO_COLLOQUIO = 'HS_OK_PRIMO_COLLOQUIO',
  HS_OK_SECONDO_COLLOQUIO = 'HS_OK_SECONDO_COLLOQUIO',
  HS_KO_NON_PRESENTATO = 'HS_KO_NON_PRESENTATO',
  HS_KO_RIPENSAMENTO = 'HS_KO_RIPENSAMENTO',
  HS_KO_RINUNCIA = 'HS_KO_RINUNCIA',
  HS_OK_AFFIANCAMENTO = 'HS_OK_AFFIANCAMENTO',
  //HR
  HR_DA_CONFERMARE = 'HR_DA_CONFERMARE',
  HR_DA_RICHIAMARE = 'HR_DA_RICHIAMARE',
  HR_DA_ASSEGNARE = 'HR_DA_ASSEGNARE',
  HR_INTERESSATO_PERCORSO_FORMATIVO = 'HR_INTERESSATO_PERCORSO_FORMATIVO',
  HR_ASSEGNATO = 'HR_ASSEGNATO',
  HR_CONTACT_DUPLICATED = 'HR_CONTACT_DUPLICATED',
  HR_KOCV = 'HR_KOCV',
  HR_KO_NO_INTERESSATO = 'HR_KO_NO_INTERESSATO',
  HR_KO_ALTRO = 'HR_KO_ALTRO',
  HR_INTERESSATO_BP = 'HR_INTERESSATO_BP',
  //SM
  SM_DA_VERIFICARE = 'SM_DA_VERIFICARE',
  SM_DA_RICHIAMARE = 'SM_DA_RICHIAMARE',
  SM_COLLOQUIO_FISSATO = 'SM_COLLOQUIO_FISSATO',
  SM_KO_NO_INTERESSATO = 'SM_KO_NO_INTERESSATO',
  SM_COLLOQUIO_NON_PRESENTATO = 'SM_COLLOQUIO_NON_PRESENTATO',
  SM_COLLOQUIO_RICHIAMATO_KO = 'SM_COLLOQUIO_RICHIAMATO_KO',
  SM_COLLOQUIO_RIFISSATO = 'SM_COLLOQUIO_RIFISSATO',
  SM_COLLOQUIO_ESEGUITO_OK = 'SM_COLLOQUIO_ESEGUITO_OK',
  SM_COLLOQUIO_ESEGUITO_KO = 'SM_COLLOQUIO_ESEGUITO_KO',
  SM_OPERATIVO = 'SM_OPERATIVO',
  SM_DA_ATTIVARE_FR = 'SM_DA_ATTIVARE_FR',
  SM_ATTIVATO_FR = 'SM_ATTIVATO_FR',
}

export enum StatusCodesArchitect {
  //HR
  HR_KO_NO_ABILITATO = 'HR_KO_NO_ABILITATO',
  HR_KO_NO_LAVORO_ESCLUSIVO = 'HR_KO_NO_LAVORO_ESCLUSIVO',
  HR_KO_ETA = 'HR_KO_ETA',
  HR_INTERESSATO_BP = 'HR_INTERESSATO_BP',
  //HS
  HS_FOLLOWUP = 'HS_FOLLOWUP',
  HS_DA_RICHIAMARE = 'HS_DA_RICHIAMARE',
  HS_SOLLECITO = 'HS_SOLLECITO',
  HS_KO_NON_INTERESSATO_RIMBORSO_SPESE = 'HS_KO_NON_INTERESSATO_RIMBORSO_SPESE',
  HS_KO_NON_INTERESSATO_LAVORO = 'HS_KO_NON_INTERESSATO_LAVORO',
  HS_KO_NON_INTERESSATO_TRASFERIMENTO = 'HS_KO_NON_INTERESSATO_TRASFERIMENTO',
  HS_KO_NON_INTERESSATO_ALTRO = 'HS_KO_NON_INTERESSATO_ALTRO',
  HS_KO_NON_INTERESSATO_ABILITAZIONE = 'HS_KO_NON_INTERESSATO_ABILITAZIONE',
  HS_KO_NON_A_TARGET = 'HS_KO_NON_A_TARGET',
  HS_OK_PRIMO_COLLOQUIO = 'HS_OK_PRIMO_COLLOQUIO',
  HS_OK_SECONDO_COLLOQUIO = 'HS_OK_SECONDO_COLLOQUIO',
  HS_KO_NON_PRESENTATO = 'HS_KO_NON_PRESENTATO',
  HS_KO_RIPENSAMENTO = 'HS_KO_RIPENSAMENTO',
  HS_KO_RINUNCIA = 'HS_KO_RINUNCIA',
  HS_OK_AFFIANCAMENTO = 'HS_OK_AFFIANCAMENTO',
  ADMIN_SENIOR_ATTIVATO = 'ADMIN_SENIOR_ATTIVATO',
  //SM
  SM_AFFIANCAMENTO_FISSATO = 'SM_AFFIANCAMENTO_FISSATO',
  SM_AFFIANCAMENTO_NON_PRESENTATO = 'SM_AFFIANCAMENTO_NON_PRESENTATO',
  SM_AFFIANCAMENTO_KO = 'SM_AFFIANCAMENTO_KO',
  SM_AFFIANCAMENTO_OK = 'SM_AFFIANCAMENTO_OK',
  SM_IN_FORMAZIONE = 'SM_IN_FORMAZIONE',
  SM_DA_ATTIVARE_FR = 'SM_DA_ATTIVARE_FR',
  SM_ATTIVATO_FR = 'SM_ATTIVATO_FR',
}

export enum OrderedCodesArchitect {
  ADMIN_ASSEGNATO = 'ADMIN_ASSEGNATO',
  ADMIN_SENIOR_ATTIVATO = 'ADMIN_SENIOR_ATTIVATO',
  //HS
  HS_FOLLOWUP = 'HS_FOLLOWUP',
  HS_DA_RICHIAMARE = 'HS_DA_RICHIAMARE',
  HS_SOLLECITO = 'HS_SOLLECITO',
  HS_KO_NON_INTERESSATO_RIMBORSO_SPESE = 'HS_KO_NON_INTERESSATO_RIMBORSO_SPESE',
  HS_KO_NON_INTERESSATO_LAVORO = 'HS_KO_NON_INTERESSATO_LAVORO',
  HS_KO_NON_INTERESSATO_TRASFERIMENTO = 'HS_KO_NON_INTERESSATO_TRASFERIMENTO',
  HS_KO_NON_INTERESSATO_ALTRO = 'HS_KO_NON_INTERESSATO_ALTRO',
  HS_KO_NON_INTERESSATO_ABILITAZIONE = 'HS_KO_NON_INTERESSATO_ABILITAZIONE',
  HS_KO_NON_A_TARGET = 'HS_KO_NON_A_TARGET',
  HS_OK_PRIMO_COLLOQUIO = 'HS_OK_PRIMO_COLLOQUIO',
  HS_OK_SECONDO_COLLOQUIO = 'HS_OK_SECONDO_COLLOQUIO',
  HS_KO_NON_PRESENTATO = 'HS_KO_NON_PRESENTATO',
  HS_KO_RIPENSAMENTO = 'HS_KO_RIPENSAMENTO',
  HS_KO_RINUNCIA = 'HS_KO_RINUNCIA',
  HS_OK_AFFIANCAMENTO = 'HS_OK_AFFIANCAMENTO',
  //HR
  HR_INTERESSATO_PERCORSO_FORMATIVO = 'HR_INTERESSATO_PERCORSO_FORMATIVO',
  HR_DA_CONFERMARE = 'HR_DA_CONFERMARE',
  HR_DA_RICHIAMARE = 'HR_DA_RICHIAMARE',
  HR_DA_ASSEGNARE = 'HR_DA_ASSEGNARE',
  HR_CONTACT_DUPLICATED = 'HR_CONTACT_DUPLICATED',
  HR_KOCV = 'HR_KOCV',
  HR_KO_NO_INTERESSATO = 'HR_KO_NO_INTERESSATO',
  HR_KO_ALTRO = 'HR_KO_ALTRO',
  HR_KO_NO_ABILITATO = 'HR_KO_NO_ABILITATO',
  HR_KO_NO_LAVORO_ESCLUSIVO = 'HR_KO_NO_LAVORO_ESCLUSIVO',
  HR_KO_ETA = 'HR_KO_ETA',
  HR_ASSEGNATO = 'HR_ASSEGNATO',
  HR_INTERESSATO_BP = 'HR_INTERESSATO_BP',
  //SM
  SM_DA_RICHIAMARE = 'SM_DA_RICHIAMARE',
  SM_COLLOQUIO_FISSATO = 'SM_COLLOQUIO_FISSATO',
  SM_COLLOQUIO_NON_PRESENTATO = 'SM_COLLOQUIO_NON_PRESENTATO',
  SM_COLLOQUIO_RIFISSATO = 'SM_COLLOQUIO_RIFISSATO',
  SM_COLLOQUIO_ESEGUITO_OK = 'SM_COLLOQUIO_ESEGUITO_OK',
  SM_COLLOQUIO_ESEGUITO_KO = 'SM_COLLOQUIO_ESEGUITO_KO',
  SM_COLLOQUIO_RICHIAMATO_KO = 'SM_COLLOQUIO_RICHIAMATO_KO',
  SM_KO_NO_INTERESSATO = 'SM_KO_NO_INTERESSATO',
  SM_AFFIANCAMENTO_FISSATO = 'SM_AFFIANCAMENTO_FISSATO',
  SM_AFFIANCAMENTO_NON_PRESENTATO = 'SM_AFFIANCAMENTO_NON_PRESENTATO',
  SM_IN_FORMAZIONE = 'SM_IN_FORMAZIONE',
  SM_AFFIANCAMENTO_KO = 'SM_AFFIANCAMENTO_KO',
  SM_AFFIANCAMENTO_OK = 'SM_AFFIANCAMENTO_OK',
  SM_OPERATIVO = 'SM_OPERATIVO',
  SM_DA_ATTIVARE_FR = 'SM_DA_ATTIVARE_FR',
  SM_ATTIVATO_FR = 'SM_ATTIVATO_FR',
}
export enum OrderedCodesCompanyAndWorkers {
  ADMIN_ASSEGNATO = 'ADMIN_ASSEGNATO',
  HR_DA_CONFERMARE = 'HR_DA_CONFERMARE',
  HR_DA_RICHIAMARE = 'HR_DA_RICHIAMARE',
  HR_DA_ASSEGNARE = 'HR_DA_ASSEGNARE',
  HR_CONTACT_DUPLICATED = 'HR_CONTACT_DUPLICATED',
  HR_KOCV = 'HR_KOCV',
  HR_KO_NO_INTERESSATO = 'HR_KO_NO_INTERESSATO',
  HR_KO_ALTRO = 'HR_KO_ALTRO',
  HR_KO_NO_ABILITATO = 'HR_KO_NO_ABILITATO',
  HR_KO_NO_LAVORO_ESCLUSIVO = 'HR_KO_NO_LAVORO_ESCLUSIVO',
  HR_KO_NO_IMPRESA = 'HR_KO_NO_IMPRESA',
  HR_ASSEGNATO = 'HR_ASSEGNATO',
  SM_DA_RICHIAMARE = 'SM_DA_RICHIAMARE',
  SM_COLLOQUIO_FISSATO = 'SM_COLLOQUIO_FISSATO',
  SM_COLLOQUIO_NON_PRESENTATO = 'SM_COLLOQUIO_NON_PRESENTATO',
  SM_COLLOQUIO_RIFISSATO = 'SM_COLLOQUIO_RIFISSATO',
  SM_COLLOQUIO_ESEGUITO_OK = 'SM_COLLOQUIO_ESEGUITO_OK',
  SM_COLLOQUIO_ESEGUITO_KO = 'SM_COLLOQUIO_ESEGUITO_KO',
  SM_COLLOQUIO_RICHIAMATO_KO = 'SM_COLLOQUIO_RICHIAMATO_KO',
  SM_KO_NO_INTERESSATO = 'SM_KO_NO_INTERESSATO',
  SM_KO_PREZZO = 'SM_KO_PREZZO',
  SM_KO_RICHIESTA_DOCUMENTI = 'SM_KO_RICHIESTA_DOCUMENTI',
  SM_KO_DOCUMENTI = 'SM_KO_DOCUMENTI',
  SM_OPERATIVO = 'SM_OPERATIVO',
}
export enum StatusCodesCompany {
  HR_KO_NO_IMPRESA = 'HR_KO_NO_IMPRESA',
  SM_KO_PREZZO = 'SM_KO_PREZZO',
  SM_KO_RICHIESTA_DOCUMENTI = 'SM_KO_RICHIESTA_DOCUMENTI',
  SM_KO_DOCUMENTI = 'SM_KO_DOCUMENTI',
}

export enum StatusCodesWorker {}

export const StatusCodes = {
  ...StatusCodesAll,
  ...StatusCodesArchitect,
  ...StatusCodesCompany,
  ...StatusCodesWorker,
};

export enum StatusCodesLabel {
  ADMIN_ASSEGNATO = 'Assegnato da sistema',
  ADMIN_SENIOR_ATTIVATO = 'Attivo (affiancamento)',
  HS_FOLLOWUP = 'Followup da fare',
  HS_DA_RICHIAMARE = 'Da richiamare',
  HS_SOLLECITO = 'Inviato sollecito mail',
  HS_KO_NON_INTERESSATO_RIMBORSO_SPESE = 'Ko non interessato - rimborso spese',
  HS_KO_NON_INTERESSATO_LAVORO = 'Ko non interessato - lavoro',
  HS_KO_NON_INTERESSATO_TRASFERIMENTO = 'Ko non interessato – trasferimento',
  HS_KO_NON_INTERESSATO_ALTRO = 'Ko non interessato - altro',
  HS_KO_NON_INTERESSATO_ABILITAZIONE = 'Ko non interessato - abilitazione',
  HS_KO_NON_A_TARGET = 'KO non a target',
  HS_OK_PRIMO_COLLOQUIO = 'OK per 1° colloquio',
  HS_OK_SECONDO_COLLOQUIO = 'OK per 2° colloquio',
  HS_KO_NON_PRESENTATO = 'Non presentato',
  HS_KO_RIPENSAMENTO = 'Ko ripensamento',
  HS_KO_RINUNCIA = 'Ko rinuncia',
  HS_OK_AFFIANCAMENTO = 'Ok affiancamento',
  HR_INTERESSATO_PERCORSO_FORMATIVO = 'Interessato al percorso formativo',
  HR_DA_CONFERMARE = 'Da contattare',
  HR_DA_RICHIAMARE = 'Da richiamare',
  HR_DA_ASSEGNARE = 'Da assegnare',
  HR_CONTACT_DUPLICATED = 'Contatto duplicato',
  HR_KOCV = 'KO CV',
  HR_KO_NO_INTERESSATO = 'KO Non interessato',
  HR_KO_ALTRO = 'KO Altro',
  HR_KO_NO_ABILITATO = 'KO No abilitato',
  HR_KO_NO_LAVORO_ESCLUSIVO = 'KO No lavoro esclusivo',
  HR_KO_ETA = 'KO Età',
  HR_KO_NO_IMPRESA = 'KO No impresa',
  HR_ASSEGNATO = 'Assegnato',
  HR_INTERESSATO_BP = 'Interessato BP',
  SM_DA_VERIFICARE = 'in attesa di approvazione',
  SM_DA_RICHIAMARE = 'Da richiamare SM',
  SM_COLLOQUIO_FISSATO = 'Colloquio fissato',
  SM_COLLOQUIO_NON_PRESENTATO = 'Colloquio non presentato',
  SM_COLLOQUIO_RIFISSATO = 'Colloquio rifissato',
  SM_COLLOQUIO_ESEGUITO_OK = 'OK Colloquio eseguito',
  SM_COLLOQUIO_ESEGUITO_KO = 'KO Colloquio eseguito',
  SM_COLLOQUIO_RICHIAMATO_KO = 'Colloquio Richiamato KO',
  SM_KO_NO_INTERESSATO = 'KO Non interessato',
  SM_AFFIANCAMENTO_FISSATO = 'Affiancamento fissato',
  SM_AFFIANCAMENTO_NON_PRESENTATO = 'Affiancamento non presentato',
  SM_IN_FORMAZIONE = 'Affiancamento in corso',
  SM_AFFIANCAMENTO_KO = 'KO Affiancamento eseguito',
  SM_AFFIANCAMENTO_OK = 'OK Affiancamento eseguito',
  SM_KO_PREZZO = 'KO Prezzo',
  SM_KO_RICHIESTA_DOCUMENTI = 'Richiesta documenti',
  SM_KO_DOCUMENTI = 'KO documenti',
  SM_OPERATIVO = 'Operativo',
  SM_DA_ATTIVARE_FR = 'Da Attivare',
  SM_ATTIVATO_FR = 'Attivo',
}

export function getStatusList(): { code: string; label: string }[] {
  const list = [];
  Object.keys(StatusCodesLabel).map((key) => {
    list.push({ code: key, label: StatusCodesLabel[key] });
  });
  return list;
}
export function getStatusListKO(): { code: string; label: string }[] {
  return getStatusList().filter((cl) => cl.code.includes('KO'));
}
export function getStatusListOK(): { code: string; label: string }[] {
  return getStatusList().filter((cl) => !cl.code.includes('KO'));
}
export function getArchitectStatusList(): { code: string; label: string }[] {
  const list = [];
  Object.keys(OrderedCodesArchitect).map((key) => {
    list.push({ code: key, label: StatusCodesLabel[key] });
  });
  return list;
}
export function getWorkerStatusList(): { code: string; label: string }[] {
  const list = [];
  Object.keys(OrderedCodesCompanyAndWorkers).map((key) => {
    list.push({ code: key, label: StatusCodesLabel[key] });
  });
  return list;
}
export function getCompanyStatusList(): { code: string; label: string }[] {
  const list = [];
  Object.keys(OrderedCodesCompanyAndWorkers).map((key) => {
    list.push({ code: key, label: StatusCodesLabel[key] });
  });
  return list;
}
