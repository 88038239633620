import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AvailabilityResponseBody, IEvent } from '@renovars/common';
import { Opportinity, PltEventType, PltRoles } from '@renovars/common/plenitude';
import { AuthServices, EventService } from '@renovars/core-ng';
import { cloneDeep } from 'lodash';
import { MessageService } from 'primeng/api';
import { switchMap, concatAll, filter, reduce, tap, catchError, of } from 'rxjs';
import { OpportunityEventService } from '../../../services/opportunity-event.service';

@Component({
  selector: 'opportunity-create-event-modal',
  template: `
    <div mat-dialog-content>
      <form [formGroup]="formAppointment" (submit)="save()">
        <div class="row">
          <div class="col-12 mt-1">
            <fullstack-input-address-box [options]="{ label: 'Indirizzo' }" formControlName="address">
            </fullstack-input-address-box>
          </div>

          <div class="col-6 mt-1">
            <fullstack-daterangepicker
              [options]="{ label: 'Range' }"
              (rangeChange)="range = $event; checkEventAvailability()"
            >
            </fullstack-daterangepicker>
          </div>

          <div class="col-6 mt-1">
            <fullstack-input-select-box [options]="{ label: 'Installatore' }" [formControl]="slot">
              <ng-container *ngFor="let s of slotList">
                <sb-option [value]="{ account: a, slot: s }" *ngFor="let a of s.accounts">
                  {{ s.start | date: 'short' }} - {{ a.fullname }}
                </sb-option>
              </ng-container>
            </fullstack-input-select-box>
          </div>

          <div class="col-12 mt-1">
            <fullstack-input-textarea [options]="{ label: 'Note', rows: 2 }" formControlName="note">
            </fullstack-input-textarea>
          </div>
        </div>

        <div class="text-end">
          <button type="button" class="btn btn-default rounded m-1" (click)="close()">Annulla</button>

          <button [disabled]="!formAppointment.valid" type="submit" class="btn btn-warning rounded m-1">
            Crea
          </button>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class OpportunityCreateEventModalComponent implements OnInit {
  formAppointment: UntypedFormGroup;
  slotList: AvailabilityResponseBody[];
  range: { start: Date; end: Date };
  slot: UntypedFormControl;
  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    private opportunityEventService: OpportunityEventService,
    @Inject(MAT_DIALOG_DATA) public data: { opportunity: Opportinity; type: PltEventType },
    public dialogRef: MatDialogRef<OpportunityCreateEventModalComponent>,
    private messageService: MessageService,
    private authService: AuthServices
  ) {}

  ngOnInit(): void {
    this.formAppointment = this.fb.group({
      contact: new UntypedFormControl('', []),
      candidate: new UntypedFormControl('', []),
      start: new UntypedFormControl('', []),
      end: new UntypedFormControl('', []),
      address: new UntypedFormControl('' || '', []),
      user: new UntypedFormControl('', []),
      note: new UntypedFormControl('', []),
      mailVisible: new UntypedFormControl(false, []),
      sendMail: new UntypedFormControl(true, []),
    });
    this.slot = new UntypedFormControl({}, []);
  }
  checkEventAvailability() {
    const eventForm = cloneDeep(this.formAppointment.value);
    if (eventForm.address && this.range && this.range.start && this.range.end) {
      const request: Partial<IEvent> = {
        start: this.range.start,
        end: this.range.end,
        address: eventForm.address,
      };
      this.authService
        .user()
        .pipe(
          switchMap((user) => {
            if (user.roles.includes(PltRoles.is_cc_operator)) {
              return this.eventService.checkEventAvailability(request).pipe(
                concatAll(),
                filter((slot: any) => slot.accounts[0]?.id === user.sub),
                reduce((a, b) => [...a, b], [])
              );
            } else {
              return this.eventService.checkEventAvailability(request);
            }
          })
        )
        .subscribe((res) => (this.slotList = res));
    }
  }
  save() {
    if (this.formAppointment.valid && this.slot.valid) {
      const eventForm = cloneDeep(this.formAppointment.value);
      const slot: { account; slot } = this.slot.value;
      eventForm.start = new Date(slot.slot.start);
      eventForm.end = new Date(slot.slot.end);
      eventForm.user = slot.account.id;
      eventForm.type = this.data.type;
      eventForm.contact = this.data.opportunity.contact;
      const source$ = this.opportunityEventService.createOpportunityEvent(
        this.data.opportunity._id,
        eventForm
      );
      source$
        .pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Evento creato con successo',
            });
          }),
          catchError((err) => {
            this.messageService.add({
              severity: 'error',
              summary: err?.message,
            });
            return of(err);
          })
        )
        .subscribe(() => this.dialogRef.close());
    }
  }
  close() {
    this.dialogRef.close();
  }
}
