import { NgModule } from '@angular/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SharedModule } from '../shared.module';
import { LeadFormComponent } from './lead-form.component';

const DECLARATIONS = [LeadFormComponent];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [SharedModule, RadioButtonModule],
  providers: [],
  exports: [...DECLARATIONS],
})
export class FormsModule {}
