<fullstack-header></fullstack-header>

<ng-template header-body>
  <h1 class="tx-title-1">Opportunità</h1>
  <h2 class="tx-subtitle mb-0">Lista</h2>
  <div class="text-end align-items-center">
    <button class="btn btn-header" (click)="downloadCsv()">
      <i class="pi pi-cloud-download" style="color: lightgray"></i>
    </button>
  </div>
</ng-template>

<fullstack-datatable
  [list]="items"
  [totalRecords]="total ?? 0"
  [options]="{ lazy: true }"
  (lazyLoad)="onLazyLoad($event)"
  (selected)="onSelect($event)"
  [cmItems]="cmItems"
>
  <div t-header>
    <div t-column>
      <ng-template t-col-title>Creato il</ng-template>
      <fullstack-daterangepicker *t-filter></fullstack-daterangepicker>
    </div>
    <div t-column>
      <ng-template t-col-title>Cognome</ng-template>
      <fullstack-input-box
        *t-filter
        [(ngModel)]="filter['cache.contact.lastName']"
        (keyup.enter)="load()"
      ></fullstack-input-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Nome</ng-template>
      <fullstack-input-box *t-filter [(ngModel)]="filter['cache.contact.firstName']" (keyup.enter)="load()">
      </fullstack-input-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Telefono</ng-template>
      <fullstack-input-box *t-filter [(ngModel)]="filter['cache.contact.phone']" (keyup.enter)="load()">
      </fullstack-input-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Status</ng-template>
      <fullstack-input-select-box *t-filter [(ngModel)]="filter['status.code']" (ngModelChange)="load()">
        <sb-option *ngFor="let code of codes" [value]="code">{{ labels[code] }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Assegnatari</ng-template>
      <fullstack-input-select-box *t-filter [(ngModel)]="filter.assigned" (ngModelChange)="load()">
        <sb-option [key]="'id'" [value]="i" *ngFor="let i of selectableUsers$ | async">{{
          i.firstName + ' ' + i.lastName
        }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Utente</ng-template>
      <fullstack-input-select-box *t-filter [(ngModel)]="filter['status.user']" (ngModelChange)="load()">
        <sb-option [key]="'id'" [value]="i" *ngFor="let i of selectableUsers$ | async">{{
          i.firstName + ' ' + i.lastName
        }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Creato da</ng-template>
      <fullstack-input-select-box *t-filter [(ngModel)]="filter.createdBy" (ngModelChange)="load()">
        <sb-option [key]="'id'" [value]="i" *ngFor="let i of selectableUsers$ | async">{{
          i.firstName + ' ' + i.lastName
        }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Marketing</ng-template>
      <fullstack-input-select-box
        *t-filter
        [(ngModel)]="filter['privacy.marketing']"
        (ngModelChange)="load()"
      >
        <sb-option [value]="true">Accettato</sb-option>
        <sb-option [value]="false">Rifiutato</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Commerciale</ng-template>
      <fullstack-input-select-box
        *t-filter
        [(ngModel)]="filter['privacy.commercial']"
        (ngModelChange)="load()"
      >
        <sb-option [value]="true">Accettato</sb-option>
        <sb-option [value]="false">Rifiutato</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Profilo</ng-template>
      <fullstack-input-select-box *t-filter [(ngModel)]="filter['privacy.profile']" (ngModelChange)="load()">
        <sb-option [value]="true">Accettato</sb-option>
        <sb-option [value]="false">Rifiutato</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column>
      <ng-template t-col-title>Soggetti Terzi</ng-template>
      <fullstack-input-select-box *t-filter [(ngModel)]="filter['privacy.otherSub']" (ngModelChange)="load()">
        <sb-option [value]="true">Accettato</sb-option>
        <sb-option [value]="false">Rifiutato</sb-option>
      </fullstack-input-select-box>
    </div>
  </div>
  <ng-template t-body let-row>
    <td>{{ row?.createdAt | date: 'dd-MM-yyyy HH:mm' }}</td>
    <td>{{ row?.cache?.contact?.lastName || row?.cache?.contact?.businessName }}</td>
    <td>{{ row?.cache?.contact?.firstName }}</td>
    <td>{{ row?.cache?.contact | getPhone }}</td>
    <td>{{ labels[row?.status?.code] }}</td>
    <td pTooltip="{{ row?.assigned | assigned: true | async }}">
      {{ row?.assigned | assigned: false | async }}
    </td>
    <td>{{ row?.status?.user | nameById | async }}</td>
    <td>{{ row?.createdBy | nameById | async }}</td>
    <td class="text-center">
      <i class="pi pi-check" *ngIf="row?.cache?.lead?.privacy?.marketing"></i>
      <i class="pi pi-times" *ngIf="!row?.cache?.lead?.privacy?.marketing"></i>
    </td>
    <td class="text-center">
      <i class="pi pi-check" *ngIf="row?.cache?.lead?.privacy?.commercial"></i>
      <i class="pi pi-times" *ngIf="!row?.cache?.lead?.privacy?.commercial"></i>
    </td>
    <td class="text-center">
      <i class="pi pi-check" *ngIf="row?.cache?.lead?.privacy?.profile"></i>
      <i class="pi pi-times" *ngIf="!row?.cache?.lead?.privacy?.profile"></i>
    </td>
    <td class="text-center">
      <i class="pi pi-check" *ngIf="row?.cache?.lead?.privacy?.otherSub"></i>
      <i class="pi pi-times" *ngIf="!row?.cache?.lead?.privacy?.otherSub"></i>
    </td>
  </ng-template>
</fullstack-datatable>
<div *ngIf="total === 0" class="alert alert-primary" role="alert">Nessuna opportunità trovato</div>
