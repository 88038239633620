import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AvailabilityResponseBody, IEvent } from '@renovars/common';
import { Lead, PltEventType, PltRoles } from '@renovars/common/plenitude';
import { AuthServices, EventService } from '@renovars/core-ng';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { MessageService } from 'primeng/api';
import { switchMap, concatAll, filter, reduce, tap, catchError, of } from 'rxjs';
import { OpportunityEventService } from '../../../services/opportunity-event.service';

@Component({
  selector: 'create-event-lead-modal',
  template: `
    <div mat-dialog-content>
      <form [formGroup]="eventAvabilityForm">
        <div class="row">
          <div class="col-12 mt-1">
            <fullstack-input-address-box [options]="{ label: 'Indirizzo' }" formControlName="address">
            </fullstack-input-address-box>
          </div>
          <div class="col-12 mt-1">
            <fullstack-datepicker
              formControlName="date"
              [options]="{ label: 'Data' }"
              (ngModelChange)="checkEventAvailability()"
            ></fullstack-datepicker>
          </div>
        </div>
      </form>
      <form [formGroup]="formAppointment" (submit)="save()">
        <div class="row">
          <!-- <div class="col-12 mt-1">
            <fullstack-input-address-box [options]="{ label: 'Indirizzo' }" formControlName="address">
            </fullstack-input-address-box>
          </div>

          <div class="col-6 mt-1">
            <fullstack-daterangepicker
              [options]="{ label: 'Range' }"
              (rangeChange)="range = $event; checkEventAvailability()"
            >
            </fullstack-daterangepicker>
          </div> -->

          <div class="col-12 mt-1">
            <fullstack-input-select-box [options]="{ label: 'Installatore' }" [formControl]="slot">
              <ng-container *ngFor="let s of slotList">
                <sb-option [value]="{ account: a, slot: s }" *ngFor="let a of s.accounts">
                  {{ s.start | date: 'short' }} - {{ a.fullname }}
                </sb-option>
              </ng-container>
            </fullstack-input-select-box>
          </div>

          <!-- <div class="col-12 mt-1">
            <fullstack-input-textarea [options]="{ label: 'Note', rows: 2 }" formControlName="note">
            </fullstack-input-textarea>
          </div> -->
        </div>

        <div class="text-end mt-4">
          <button type="button" class="btn btn-default rounded m-1" (click)="close()">Annulla</button>

          <button [disabled]="!eventAvabilityForm.valid" type="submit" class="btn btn-warning rounded m-1">
            Crea
          </button>
        </div>
      </form>
    </div>
  `,
  styles: [],
})
export class CreateEventLeadModalComponent implements OnInit {
  formAppointment: UntypedFormGroup;
  eventAvabilityForm: UntypedFormGroup;
  slotList: AvailabilityResponseBody[];
  slot: UntypedFormControl;
  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    private opportunityEventService: OpportunityEventService,
    @Inject(MAT_DIALOG_DATA) public data: { lead: Lead; type: PltEventType },
    public dialogRef: MatDialogRef<CreateEventLeadModalComponent>,
    private messageService: MessageService,
    private authService: AuthServices
  ) {}

  ngOnInit(): void {
    this.eventAvabilityForm = this.fb.group({
      address: new UntypedFormControl(this.data.lead.status?.metadata?.address, []),
      date: new UntypedFormControl(this.data.lead.status?.metadata?.date, []),
    });
    this.formAppointment = this.fb.group({});
    this.slot = new UntypedFormControl({}, []);
    this.checkEventAvailability();
  }
  checkEventAvailability() {
    const eventForm = cloneDeep(this.eventAvabilityForm.value);
    if (eventForm.address && eventForm.date) {
      const request: Partial<IEvent> = {
        start: dayjs(eventForm.date).startOf('day').toDate(),
        end: dayjs(eventForm.date).endOf('day').toDate(),
        address: eventForm.address,
      };
      this.authService
        .user()
        .pipe(
          switchMap((user) => {
            if (user.roles.includes(PltRoles.is_cc_operator)) {
              return this.eventService.checkEventAvailability(request).pipe(
                concatAll(),
                filter((slot: any) => slot.accounts[0]?.id === user.sub),
                reduce((a, b) => [...a, b], [])
              );
            } else {
              return this.eventService.checkEventAvailability(request);
            }
          })
        )
        .subscribe((res) => (this.slotList = res));
    }
  }
  save() {
    if (this.eventAvabilityForm.valid && this.slot.valid) {
      const slot: { account; slot } = this.slot.value;
      const payload = {
        start: new Date(slot.slot.start),
        end: new Date(slot.slot.end),
        address: this.eventAvabilityForm.get('address')?.value,
        user: slot.account.id,
        type: this.data.type,
        contact: this.data.lead.contact,
      };
      let source$;
      if (this.data.type == PltEventType.SOPRALLUOGO) {
        source$ = this.eventService.create(payload);
      } else {
        source$ = this.opportunityEventService.createOpportunityEvent(this.data.lead._id, payload);
      }
      source$
        .pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Evento creato con successo',
            });
          }),
          catchError((err) => {
            this.messageService.add({
              severity: 'error',
              summary: err?.message,
            });
            return of(err);
          })
        )
        .subscribe(() => this.dialogRef.close());
    }
  }
  close() {
    this.dialogRef.close();
  }
}
