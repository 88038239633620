import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAddress, ICommandHistory, ITimelineUiItem } from '@renovars/common';
import {
  DuplicateLeadReturnValue,
  IUpdateLead,
  Lead,
  LeadFilter,
  PltFlow,
  PltLeadStatusCode,
} from '@renovars/common/plenitude';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/plenitude-ng/src/environments/environment';
import { MessageService } from 'primeng/api';
import { catchError, delay, map, Observable, of, switchMap, tap } from 'rxjs';
import { CsvUtilsService } from '../../services/csv-utils.service';

@Injectable()
export class LeadApiService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private csvUtils: CsvUtilsService
  ) {}
  createFlow(data: Record<string, unknown>): Observable<DuplicateLeadReturnValue> {
    return this.http.post(`${environment.apiGatewayURL}/plenitude/leads`, data).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Lead creato con successo',
        });
      }),
      map(() => {
        return { status: 'OK' } as DuplicateLeadReturnValue;
      }),
      catchError((err) => {
        if (err.status === 409) {
          return of({ status: 'DUPLICATE', duplicate: err.message } as DuplicateLeadReturnValue);
        }
        this.messageService.add({
          severity: 'error',
          summary: err?.message,
        });
        return of({ status: 'ERROR', duplicate: null } as DuplicateLeadReturnValue);
      }),
      delay(1000)
    );
  }
  /**
   * esempio
      {
        offset: 0,
        limit: 10,
        filter: {
          'cache.data.contact.lastName': {
            $regex: 'ze',
          },
        },
      }
   */
  createBrowsingFilter(value: LeadFilter) {
    const query: { [k in keyof LeadFilter]?: unknown } = {};
    if (value.assigned) {
      query['assigned'] = {
        $in: [value.assigned],
      };
    }
    if (value['cache.addressOp.locality']) {
      query['cache.addressOp.locality'] = {
        $eq: value['cache.addressOp.locality'],
      };
    }
    if (value['cache.contact.firstName']) {
      query['cache.contact.firstName'] = {
        $regex: value['cache.contact.firstName'],
      };
    }
    if (value['cache.contact.lastName']) {
      query['cache.contact.lastName'] = {
        $regex: value['cache.contact.lastName'],
      };
    }
    if (value['cache.contact.phone']) {
      query['cache.contact.phones.phoneNumber'] = {
        $regex: value['cache.contact.phone'],
      };
    }
    if (value['createdBy']) {
      query['createdBy'] = {
        $in: value['createdBy'],
      };
    }
    if (value['status.code']) {
      query['status.code'] = {
        $eq: value['status.code'],
      };
    }
    if (value['status.user']) {
      query['status.user'] = {
        $eq: value['status.user'],
      };
    }
    if (value['privacy.marketing'] !== null) {
      query['privacy.marketing'] = {
        $eq: value['privacy.marketing'],
      };
    }
    if (value['privacy.commercial'] !== null) {
      query['privacy.commercial'] = {
        $eq: value['privacy.commercial'],
      };
    }
    if (value['privacy.profile'] !== null) {
      query['privacy.profile'] = {
        $eq: value['privacy.profile'],
      };
    }
    if (value['privacy.otherSub'] !== null) {
      query['privacy.otherSub'] = {
        $eq: value['privacy.otherSub'],
      };
    }
    if (value['createdAt']?.start && value['createdAt']?.end) {
      query['createdAt'] = {
        $gte: value['createdAt'].start,
        $lte: value['createdAt'].end,
      };
    }
    return query;
  }
  browse(
    filter:
      | {
          offset: number;
          limit: number;
          sort: [{ by: string; order: number }];
          filter: LeadFilter;
        }
      | any
  ): Observable<{ items: PltFlow[]; total: number }> {
    return this.http.post<{ items: PltFlow[]; total: number }>(
      `${environment.apiGatewayURL}/plenitude/leads/browse`,
      {
        offset: filter.offset,
        limit: filter.limit,
        sort: filter.sort,
        filter: this.createBrowsingFilter(filter.filter),
      }
    );
  }
  browseForCsv(
    filter:
      | {
          offset: number;
          limit: number;
          sort: [{ by: string; order: number }];
          filter: any;
        }
      | any
  ) {
    return this.http
      .post<{ items: PltFlow[]; total: number }>(`${environment.apiGatewayURL}/plenitude/plt-flow/browse`, {
        offset: 0,
        limit: 1000,
        sort: filter.sort,
        filter: this.createBrowsingFilter(filter.filter),
      })
      .pipe(
        map((result) => result.items),
        switchMap((values) => this.csvUtils.mapCsv(of(values), 'lead'))
      );
  }
  getById(id: string) {
    return this.http.get<Lead>(`${environment.apiGatewayURL}/plenitude/leads/${id}`);
  }
  getTimelineById(id: string) {
    return this.http.get<ITimelineUiItem[]>(`${environment.apiGatewayURL}/plenitude/leads/timeline/${id}`);
  }
  moveState(id: string, command: Partial<ICommandHistory<typeof PltLeadStatusCode>>) {
    return this.http.put(`${environment.apiGatewayURL}/plenitude/leads/status/${id}`, command);
  }
  assignLead(id: string, payload: { assigned: string; date: Date; address: IAddress; note: string }) {
    return this.http.put(`${environment.apiGatewayURL}/plenitude/leads/assign/${id}`, payload).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Lead assegnato con successo',
        });
      }),
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: err?.message,
        });
        return of(err);
      })
    );
  }
  updateLead(id: string, data: IUpdateLead) {
    return this.http.put<Lead>(`${environment.apiGatewayURL}/plenitude/leads/${id}`, data).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Lead aggiornato con successo',
        });
      }),
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: err?.message,
        });
        throw err;
      })
    );
  }
  recallLead(id: string) {
    return this.http.post<Lead>(`${environment.apiGatewayURL}/plenitude/leads/recall-lead`, { id }).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Lead aggiornato con successo',
        });
      }),
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: err?.message,
        });
        return of(null);
      })
    );
  }
}
