import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { SharedModule } from '../shared.module';
import { DocumentManagerComponent } from './document-manager/document-manager.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { HistoryComponent } from './history.component';
import { TimelineModule } from 'primeng/timeline';
import { PipesModule } from '../pipes/pipes.module';

const DECLARATIONS = [DocumentManagerComponent, DocumentUploadComponent, HistoryComponent];

@NgModule({
  declarations: DECLARATIONS,
  imports: [SharedModule, MatListModule, TimelineModule, PipesModule],
  providers: [],
  exports: [...DECLARATIONS],
})
export class SharedComponentsModule {}
