import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CommonRoles,
  EventType,
  IAvailabilitySlot,
  IContact,
  IDomainValue,
  IEvent,
  IJwtPayload,
  IPaginateRequest,
  IUser,
} from '@renovars/common';
import { FiRoles, getUpline, IFiAppointmentSearchDTO } from '@renovars/common/facileimmobiliare';
import { AuthServices, ContactsServices } from '@renovars/fe-core-libs/services';
import { CalendarioService } from '@renovars/fe-core-libs/services/calendario.service';
import { DomainValuesService } from '@renovars/fe-core-libs/services/domain-values.service';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { CalendarioModuleConfig, CALENDARIO_CONFIG_PROVIDER } from '../../../module.options';
import { DeletePersonalModalComponent } from '../../modals/delete-personal-modal/delete-personal-modal.component';
import { PersonalEventModalComponent } from '../../modals/personal-event-modal/personal-event-modal.component';

@Component({
  selector: 'calendario',
  templateUrl: `./calendario.html`,
})
export class CalendarioComponent implements OnInit {
  list: IEvent[];
  selectedUserAvailability: IAvailabilitySlot[];
  storeManagers$: Observable<IUser[]>;
  contacts$: Observable<IContact[]>;
  paginateRequest: IPaginateRequest<IFiAppointmentSearchDTO> = {
    form: { showOnlyValidEvents: true, canSyncGraph: true },
    skip: 0,
    limit: 1000,
  };
  selected;
  eventTypes: IDomainValue<unknown>[];
  domainValuesList$: Observable<IDomainValue<unknown>[]>;
  currentUser: IJwtPayload;
  agentList$: Observable<IUser[]>;
  networkList$: Observable<IUser[]>;
  constructor(
    @Inject(CALENDARIO_CONFIG_PROVIDER) protected config: CalendarioModuleConfig,
    protected calendarioService: CalendarioService,
    protected usersService: UsersService,
    protected contactsService: ContactsServices,
    protected authService: AuthServices,
    protected dialog: MatDialog,
    protected domainValueService: DomainValuesService
  ) {}

  ngOnInit(): void {
    this.storeManagers$ = this.usersService.getUsersListDownline(
      [FiRoles.IS_ADMIN, FiRoles.IS_BACK_OFFICE, FiRoles.IS_PROPERTY_FINDER],
      null
    );

    this.domainValueService.list().subscribe((res) => {
      if (res) {
        this.eventTypes = res.filter((d: IDomainValue<unknown>) => d.type === 'event_type');
      }
    });

    this.authService.user().subscribe((u) => (this.currentUser = u));

    this.loadAgentList(null);

    this.networkList$ = this.usersService.getUsersListDownline(
      [FiRoles.IS_ADMIN],
      getUpline(FiRoles.IS_STORE_MANAGER)
    );
  }

  searchContact(searchString) {
    this.contacts$ = this.contactsService.searchByNominative(searchString).pipe(pluck('data'));
  }

  search() {
    this.calendarioService
      .search(_.cloneDeep(this.paginateRequest))
      .subscribe((res) => (this.list = res.data));
  }

  searchByAddress(event) {
    this.paginateRequest.form.address = event.target.value;
    this.search();
  }

  searchByUser(userId: string) {
    this.selectedUserAvailability = null;
    this.usersService.getUserMeta(userId).subscribe((res) => {
      this.selectedUserAvailability = res ? res.availabilitySlots : null;
    });

    this.paginateRequest.form.network = undefined;
    this.paginateRequest.form.userId = userId;
    this.search();
  }

  viewChange(data) {
    this.paginateRequest.form.eventStartRange = { start: data.currentStart, end: data.currentEnd };
    this.search();
  }

  onEventClick(event) {
    if (event.event && Object.keys(event.event.extendedProps).length > 0) {
      const isSameUser = this.currentUser.sub === event.event.extendedProps.insertUser;
      const isAdmin = this.currentUser.roles.includes(CommonRoles.IS_ADMIN);
      const isPersonal = event.event.extendedProps.type === EventType.PERSONALE;
      if (isPersonal) {
        if (isSameUser || isAdmin) {
          this.dialog
            .open(DeletePersonalModalComponent, {
              data: {
                event: event.event.extendedProps,
              },
            })
            .afterClosed()
            .subscribe((res) => {
              if (res) {
                this.search();
              }
            });
        }
      } else {
        this.dialog.open(this.config.eventModalClass, {
          minWidth: 800,
          data: {
            event: event.event.extendedProps,
          },
        });
      }
    }
  }

  getColor(event: IEvent) {
    switch (event.type) {
      case 1:
        return '#29b6f6';
      case 2:
        return '#66bb6a';
      case EventType.PERSONALE:
        return '#FF5252';
      default:
        return '#ffa726';
    }
  }

  createPersonalEventClick() {
    this.dialog
      .open(PersonalEventModalComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.search();
        }
      });
  }

  getEvent(e) {
    let title = '';
    if (e?.user && typeof e?.user == 'object') {
      title = e.contact?.businessName
        ? e?.user.firstName + ' ' + e?.user.lastName + ' | ' + e?.contact?.businessName
        : e?.user.firstName + ' ' + e?.user.lastName;
      title += ' | ';
    }

    if (e.cache?.contact && e.cache.contact.firstName && e.cache.contact.lastName) {
      title += e.cache.contact.firstName + ' ' + e.cache.contact.lastName;
    }

    if (e.type === EventType.PERSONALE) {
      if(e.syncedFromOutlookAt) {
        title = `[O] ${e.note}: ${e?.user?.firstName} ${e?.user?.lastName}`;
      } else {
        title = `Riservato da ${e?.user?.firstName} ${e?.user?.lastName}`;
      }
    }

    return {
      title,
      start: e.start,
      end: e.end,
      color: this.getColor(e),
      extendedProps: e,
    };
  }

  onDateClick(event) {
    this.dialog
      .open(PersonalEventModalComponent, {
        data: {
          start: event.date,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.search();
        }
      });
  }

  loadAgentList(rootAgent) {
    this.agentList$ = this.usersService.getUsersListDownline([FiRoles.IS_ADMIN], null, rootAgent);
  }

  setNetworkFilter(value: string) {
    this.paginateRequest.form.userId = undefined;
    this.paginateRequest.form.network = value;
    this.search();
  }
}
