import { LeadStateEnum } from './projects-status.interface';

export const ProjectsEcStatusMap = {
  1: { ec: LeadStateEnum.DA_CHIAMARE, fi: LeadStateEnum.DA_CHIAMARE },
  2: { ec: LeadStateEnum.KO_NON_INTERESSATO, fi: LeadStateEnum.IN_LAVORAZIONE },
};

export enum EcArchiveStateEnum {
  CHIAMATA_DA_VALUTARE = 100,
}
