import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  PltEventType,
  PltFlow,
  PltRoles,
  PltLeadStatusCode,
  PltStatusLabels,
  getPltUserRole,
  Lead,
} from '@renovars/common/plenitude';
import { getEnumKeyByEnumValue } from '@renovars/common/recruit';
import { AuthServices, FlowMenuService } from '@renovars/core-ng';
import { MenuItem } from 'primeng/api';
import { CreateEventLeadModalComponent } from '../modals/create-event-lead-modal.component';
import { LeadApiService } from '../lead-api';
import { MoveLeadStateComponent } from '../modals/move-lead-state.component';
import { AssignLeadModalComponent } from '../modals/assign-lead-modal.component';
import { CreateOpportunityComponent } from '../modals/create-opportunity.component';
import { IUser } from '@renovars/common';
import { map, Observable } from 'rxjs';
import { PltUsersCacheService } from '../../../services/plt-users-cache.service';
import { EmptyLeadFilter } from './lead.filter.interface';

@Component({
  selector: 'lead-list',
  templateUrl: './lead-list.component.html',
  styles: ['p-sortable-columns { width: 15px; }'],
})
export class LeadListComponent {
  cmItems: MenuItem[];
  items: PltFlow[];
  total: number;
  flow: PltFlow;
  labels = PltStatusLabels;
  codes = Object.values(PltLeadStatusCode);
  selectableUsers$: Observable<IUser[]>;
  filter = EmptyLeadFilter;
  offset = 0;
  limit = 10;
  constructor(
    private flowMenuService: FlowMenuService<typeof PltRoles, typeof PltLeadStatusCode, PltStatusLabels>,
    private cacheUserService: PltUsersCacheService,
    private pltFlowService: LeadApiService,
    private authService: AuthServices,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.selectableUsers$ = this.cacheUserService.getUsers();
  }
  load() {
    this.pltFlowService
      .browse({
        offset: this.offset,
        limit: this.limit,
        filter: this.filter,
        sort: [
          {
            by: '_id',
            order: -1,
          },
        ],
      })
      .subscribe((res) => {
        this.items = res.items;
        this.total = res.total;
      });
  }
  onLazyLoad(event) {
    this.offset = event.first ?? 0;
    this.limit = event.rows ?? 10;
    this.load();
  }
  onSelect(selectedLead: Lead) {
    if (selectedLead) {
      this.authService
        .user()
        .pipe(
          map((u) =>
            this.flowMenuService.createMenuVoice(selectedLead.status.code, getPltUserRole(u)).map((voice) => {
              const item: MenuItem = {
                label: PltStatusLabels[voice],
                icon: voice.includes('KO')
                  ? 'pi pi-ban'
                  : voice.includes('OK')
                  ? 'pi pi-check-circle'
                  : 'pi pi-calendar',
                command: (event) => {
                  if (!event.item.label) {
                    return;
                  }
                  const status = getEnumKeyByEnumValue(PltStatusLabels, event.item.label);
                  if (status.includes(PltLeadStatusCode.ASSEGNAZIONE_TECNICO_INSTALLATORE)) {
                    this.dialog
                      .open(CreateEventLeadModalComponent, {
                        data: {
                          lead: selectedLead,
                          type: PltEventType.SOPRALLUOGO,
                        },
                      })
                      .afterClosed()
                      .subscribe(() => this.load());
                  } else if (status.includes(PltLeadStatusCode.SOPRALLUOGO_FISSATO)) {
                    this.dialog
                      .open(AssignLeadModalComponent, {
                        data: {
                          lead: selectedLead,
                          code: PltLeadStatusCode.SOPRALLUOGO_FISSATO,
                        },
                      })
                      .afterClosed()
                      .subscribe(() => this.load());
                  } else if (status.includes(PltLeadStatusCode.SOPRALLUOGO_EFFETTUATO)) {
                    this.dialog
                      .open(CreateOpportunityComponent, {
                        data: {
                          lead: selectedLead,
                          code: status,
                        },
                      })
                      .afterClosed()
                      .subscribe(() => this.load());
                  } else {
                    this.dialog
                      .open(MoveLeadStateComponent, {
                        data: {
                          lead: selectedLead,
                          code: status,
                        },
                      })
                      .afterClosed()
                      .subscribe(() => this.load());
                  }
                },
              };
              return item;
            })
          )
        )
        .subscribe((items) => {
          this.cmItems = items;
          this.cmItems.push({
            label: 'Dettaglio',
            icon: 'pi pi-check-circle',
            command: () => {
              this.router.navigateByUrl(`flows/details/upsert?id=${selectedLead._id}`);
            },
          });
          if (selectedLead.status?.metadata?.opportunityRef) {
            this.cmItems.push({
              label: 'Vai a Opportunità',
              icon: 'pi pi-check-circle',
              command: () => {
                this.router.navigateByUrl(
                  `opportunity/details/upsert?id=${selectedLead.status.metadata.opportunityRef}`
                );
              },
            });
          }
        });
    }
  }
  downloadCsv() {
    return this.pltFlowService
      .browseForCsv({
        offset: this.offset,
        limit: this.limit,
        filter: this.filter,
        sort: [
          {
            by: '_id',
            order: -1,
          },
        ],
      })
      .subscribe();
  }
  rangeChange(event: { start: Date; end: Date }) {
    this.filter.createdAt = event;
    this.load();
  }
}
