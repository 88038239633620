import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PltEventLabelType, PltEventType, PltFlow } from '@renovars/common/plenitude';
import { ConfirmationService, MessageService, MenuItem } from 'primeng/api';
import {
  AppointmentListAbstractComponent,
  AuthServices,
  ContactsServices,
  EventService,
  EventSharedService,
} from '../../../../../../libs/fe/core-ng/src';
import { DomainValuesService } from '../../../../../../libs/fe/core-ng/src/lib/services/domain-values.service';
import { UsersService } from '../../../../../../libs/fe/core-ng/src/lib/services/users.service';
import { CommonRoles, IEvent } from '../../../../../../libs/shared/common/src';

@Component({
  selector: 'plt-appointment',
  templateUrl: './plt-appointment.component.html',
  styles: [],
  providers: [ConfirmationService],
})
export class PltAppointmentComponent extends AppointmentListAbstractComponent implements OnInit {
  gotoEdit() {
    throw new Error('Method not implemented.');
  }
  gotoResult() {
    throw new Error('Method not implemented.');
  }
  types = ['OPERATOR'].map((v) => ({
    key: v,
    value: v,
  }));
  headerLess = false;
  selectedType: string;
  selected: IEvent & { cache: { flow: PltFlow } };
  eventLabels = PltEventLabelType;
  eventTypeCodes = Object.values(PltEventType).filter((v) => !!this.eventLabels[v]);
  constructor(
    usersServices: UsersService,
    eventService: EventService,
    eventSharedService: EventSharedService,
    contactsService: ContactsServices,
    domainValuesService: DomainValuesService,
    private authService: AuthServices,
    private dialog: MatDialog,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    super(usersServices, eventService, eventSharedService, contactsService, domainValuesService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.authService.user().subscribe((user) => {
      const baseItem: MenuItem[] = [
        {
          label: 'Dettagli Candidato',
          icon: 'pi pi-fw pi-search',
          command: () => {
            this.gotoDetail();
          },
        },
      ];
      this.cmItems = baseItem;
    });
  }
  searchBySm(event) {
    this.paginateRequest.form = {
      userId: event,
    };
    super.search();
  }

  gotoDetail() {
    this.router.navigateByUrl(`/flows/details/upsert?id=${this.selected?.cache?.flow?._id}`);
  }

  deleteAction() {
    const selected = this.selected as any;
    this.confirmationService.confirm({
      message: 'sei sicuro di voler cancellare questo evento?',
      header: 'Conferma',
      icon: 'pi pi-info-circle',
      accept: () => {
        if (!selected && !selected._id) return;
        this.eventService.delete(selected._id).subscribe(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Evento',
            detail: 'Evento eliminato correttamente',
          });
        });
      },
      reject: () => {},
    });
  }

  onLazyLoad(event: any): void {
    this.paginateRequest.skip = event.first;
    this.paginateRequest.limit = event.rows;
    super.search();
  }
}
