import { NgModule } from '@angular/core';
import {
  DomainValuesModule,
  GuardsModule,
  NetworkModule,
  ProfileModule,
  SiteModule,
  UtentiModule,
} from '@renovars/core-ng';
import { environment } from '../../environments/environment';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';
import { UtentiService } from '../services/utenti.service';
import { PltAppointmentModule } from '../features/appointment/plt-appointment.module';
import { SharedModule } from '../shared.module';
import { PltCalendarModule } from '../features/plt-calendar/plt-calendar.module';
import { SharedComponentsModule } from '../components/components.module';
import { OpportunityModule } from './plt-opportunity/opportunity.module';
import { ServicesModule } from '../services/services.module';
import { LeadModule } from './lead/lead.module';
const DECLARATIONS = [];

@NgModule({
  declarations: DECLARATIONS,
  imports: [
    HomeModule,
    LoginModule,
    GuardsModule,
    SharedModule,
    UtentiModule.forRoot({
      userMetaEndpoint: `${environment.apiGatewayURL}/plenitude/user-meta`,
      service: new UtentiService(),
      excludeField: {
        site: true,
        bc: true,
        acceptStar: true,
      },
    }),
    NetworkModule.forRoot({ networkURL: `${environment.apiGatewayURL}/plenitude/network` }),
    PltAppointmentModule,
    DomainValuesModule.forRoot({
      apiApplicationURL: `${environment.apiGatewayURL}/plenitude`,
    }),
    ProfileModule,
    PltCalendarModule,
    LeadModule,
    OpportunityModule,
    SiteModule.forRoot({
      url: `${environment.apiGatewayURL}/plenitude`,
      exclude: { bcCode: false, phone: false },
    }),
    SharedComponentsModule,
    ServicesModule,
  ],
  providers: [],
  exports: [...DECLARATIONS],
})
export class FeauturesModule {}
